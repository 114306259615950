import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TagBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { LimitType } from "model/schema/globalTypes";
import React from "react";
import * as Yup from "yup";
import { ServiceProductCreatable } from "./types";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    border: "solid 1px #dcdcdc",
    width: "100%"
  },
  grid: { marginBottom: theme.spacing(2) },
  label: {
    color: "#464646",
    fill: "#464646",
    fontSize: "12px",
    font: "inherit",
    fontWeight: 600
  },
  formControl: {
    margin: 0
  },
  input: {
    fill: "#464646",
    font: "inherit",
    outline: "none",
    flex: "auto",
    display: "block",
    wordBreak: "break-word",
    width: "100%",
    maxWidth: "100%",
    fontSize: "1rem",
    borderRadius: 2,
    lineHeight: 1.32,
    color: "#464646",
    border: "solid 1px #8c8c8c",
    padding: "5px"
  },
  rootInput: { backgroundColor: "#fff", margin: 0, padding: 0, border: "0px" },
  button: {
    marginTop: theme.spacing(2)
  },
  error: {
    color: "#f44336",
    fontSize: "0.75rem",
    marginTop: theme.spacing(1)
  }
}));

const validationSchema = Yup.object().shape({
  apiKeyId: Yup.string().required("API Key is required"),
  name: Yup.string().required("Name is required"),
  limitType: Yup.string()
    .oneOf([LimitType.Soft, LimitType.Hard], "Invalid limit type")
    .required("Limit type is required"),
  transactionLimit: Yup.number()
    .integer("Transaction limit must be an integer")
    .positive("Transaction limit must be positive")
    .required("Transaction limit is required"),
  expirationDate: Yup.date().required("Expiration date and time are required"),
  services: Yup.array()
    .of(Yup.string().required("Service key is required"))
    .min(1, "At least one service is required")
    .required("Services are required")
});

interface ApiKey {
  id: string;
  name: string;
}

interface ServiceProductFormProps {
  solutionKey: Key;
  apiKeys: ApiKey[];
  avilableProductServices: Key[];
  onSubmit: (serviceProduct: ServiceProductCreatable) => void;
}

const ServiceProductForm: React.FC<ServiceProductFormProps> = ({
  onSubmit,
  apiKeys,
  avilableProductServices,
  solutionKey
}) => {
  const classes = useStyles();
  const initialStateWithDefaults: Partial<ServiceProductCreatable> = {
    apiKeyId: "",
    limitType: LimitType.Soft,
    transactionLimit: 1000,
    services: []
  };
  return (
    <Formik
      initialValues={initialStateWithDefaults}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        try {
          validationSchema.validateSync(values, { abortEarly: false });
          onSubmit(values as ServiceProductCreatable);
        } catch (err) {
          notify("Unexpected Validation error: " + err, "error", 3000);
          setSubmitting(false);
          return;
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, touched, errors }) => (
        <Form className={classes.container}>
          <Grid container direction="column">
            <Grid item className={classes.grid}>
              <label htmlFor="name" className={classes.label}>
                Name
              </label>
              <Field
                as={TextField}
                id="name"
                name="name"
                variant="outlined"
                fullWidth
                error={Boolean(errors.name)}
                InputProps={{
                  classes: {
                    root: classes.rootInput,
                    input: classes.input
                  }
                }}
              />
              <ErrorMessage
                name="name"
                component="div"
                className={classes.error}
              />
            </Grid>
            <Grid item className={classes.grid}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="apiKeyId" className={classes.label}>
                  API Key
                </InputLabel>
                <Field
                  as={Select}
                  id="apiKeyId"
                  name="apiKeyId"
                  label="API Key"
                  error={touched.apiKeyId && Boolean(errors.apiKeyId)}
                  inputProps={{
                    classes: {
                      root: classes.rootInput,
                      input: classes.input
                    }
                  }}
                >
                  {apiKeys.map(apiKey => (
                    <MenuItem key={apiKey.id} value={apiKey.id}>
                      {apiKey.name}
                    </MenuItem>
                  ))}
                </Field>
                <ErrorMessage
                  name="apiKeyId"
                  component="div"
                  className={classes.error}
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.grid}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="limitType" className={classes.label}>
                  Limit Type
                </InputLabel>
                <Field
                  as={Select}
                  id="limitType"
                  name="limitType"
                  label="Limit Type"
                  error={touched.limitType && Boolean(errors.limitType)}
                  inputProps={{
                    classes: {
                      root: classes.rootInput,
                      input: classes.input
                    }
                  }}
                >
                  <MenuItem value={LimitType.Soft}>Soft</MenuItem>
                  <MenuItem value={LimitType.Hard}>Hard</MenuItem>
                </Field>
                <ErrorMessage
                  name="limitType"
                  component="div"
                  className={classes.error}
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.grid}>
              <label htmlFor="transactionLimit" className={classes.label}>
                Transaction Limit
              </label>
              <Field
                as={TextField}
                id="transactionLimit"
                name="transactionLimit"
                type="number"
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="transactionLimit" />}
                error={
                  touched.transactionLimit && Boolean(errors.transactionLimit)
                }
                InputProps={{
                  classes: {
                    root: classes.rootInput,
                    input: classes.input
                  }
                }}
              />
            </Grid>
            <Grid item className={classes.grid}>
              <label htmlFor="expirationDate" className={classes.label}>
                Expiration Date and Time
              </label>
              <Field
                as={TextField}
                id="expirationDate"
                name="expirationDate"
                type="datetime-local"
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="expirationDate" />}
                error={touched.expirationDate && Boolean(errors.expirationDate)}
                InputProps={{
                  classes: {
                    root: classes.rootInput,
                    input: classes.input
                  }
                }}
              />
            </Grid>
            <Grid item className={classes.grid}>
              <label htmlFor="solutionKey" className={classes.label}>
                Solution
              </label>
              <TextField
                id="solutionKey"
                name="solutionKey"
                variant="outlined"
                fullWidth
                value={solutionKey}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.rootInput,
                    input: classes.input
                  }
                }}
              />
            </Grid>
            <Grid item className={classes.grid}>
              <label htmlFor="services" className={classes.label}>
                Services
              </label>
              <FieldArray name="services">
                {({ push, remove, form }) => (
                  <div>
                    <Field name="services">
                      {({ field, form }: { field: any; form: any }) => (
                        <TagBox
                          dataSource={avilableProductServices}
                          value={field.value}
                          onValueChanged={(options: any) => {
                            const { value: services } = options;
                            if (
                              JSON.stringify(form.values.services) !==
                              JSON.stringify(services)
                            ) {
                              form.setFieldValue("services", services);
                            }
                          }}
                          multiline={true}
                          showDropDownButton
                          focusStateEnabled={true}
                          width={"100%"}
                          noDataText="No services available"
                          className={classes.rootInput}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="services"
                      component="div"
                      className={classes.error}
                    />
                  </div>
                )}
              </FieldArray>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              className={classes.button}
            >
              Create Service Product
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ServiceProductForm;
