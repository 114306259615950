import { Alert, AlertTitle, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import ServiceProductItem from "./ServiceProductItem";
import { ServiceProduct } from "./types";

interface ServiceProductListProps {
  serviceProducts: ServiceProduct[];
  onClick: (id: string) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    border: "solid 1px #dcdcdc",
    width: "100%",
    marginTop: theme.spacing(2)
  }
}));

const ServiceProductList: React.FC<ServiceProductListProps> = ({
  serviceProducts,
  onClick
}) => {
  const classes = useStyles();

  return serviceProducts.length > 0 ? (
    <Grid container spacing={2} justifyContent="flex-start">
      {serviceProducts.map(serviceProduct => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={serviceProduct.id}
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          <ServiceProductItem
            serviceProduct={serviceProduct}
            onClick={onClick}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Alert severity="info" className={classes.container}>
      <AlertTitle>No Service Products Available</AlertTitle>
      There are currently no service products available. As a result, the
      services within this solution will not be accessible from any API Key
      until a service product is created. Please create the necessary service
      products to grant access to the required services.
    </Alert>
  );
};

export default ServiceProductList;
