import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TransactionPackage } from "model/schema/globalTypes";
import React from "react";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #dcdcdc",
    padding: theme.spacing(2)
  },
  grid: { marginBottom: theme.spacing(2) },
  label: {
    color: "#464646",
    fill: "#464646",
    fontSize: "12px",
    font: "inherit",
    fontWeight: 600
  },
  input: {
    fill: "#464646",
    font: "inherit",
    outline: "none",
    flex: "auto",
    display: "block",
    wordBreak: "break-word",
    width: "100%",
    maxWidth: "100%",
    fontSize: "1rem",
    borderRadius: 2,
    lineHeight: 1.32,
    color: "#464646",
    border: "solid 1px #8c8c8c",
    padding: "5px"
  },
  rootInput: { backgroundColor: "#fff", margin: 0, padding: 0, border: "0px" },
  container: {
    padding: theme.spacing(2),
    border: "solid 1px #dcdcdc",
    width: "100%"
  },
  title: {
    color: "#1e1e1e",
    fontSize: "14.4px",
    fill: "#464646",
    font: "inherit",
    margin: 0,
    letterSpacing: 0,
    fontWeight: 600
  },
  disabledButton: {
    backgroundColor: "#C7CFD4 !important",
    color: "#68777B !important"
  }
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required")
});

interface ApiKeyState {
  name: string;
  transactionPackage: TransactionPackage;
  enabled: boolean;
}

interface ApiKeyFormProps {
  title: string;
  submitText: string;
  initialState: ApiKeyState;
  onSubmit: (apiKeyDefinition: ApiKeyState) => void;
}

const ApiKeyForm: React.FC<ApiKeyFormProps> = ({
  title,
  submitText,
  initialState,
  onSubmit
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, touched, errors }) => (
        <Form>
          <Grid container justifyContent="center">
            <div className={classes.container}>
              <Grid container direction="column">
                <Typography className={classes.title}>{title}</Typography>
                <Grid container direction="column">
                  <Grid item className={classes.grid}>
                    <label htmlFor="name" className={classes.label}>
                      Name
                    </label>
                    <Field
                      as={TextField}
                      id="name"
                      name="name"
                      variant="outlined"
                      fullWidth
                      helperText={<ErrorMessage name="name" />}
                      error={touched.name && Boolean(errors.name)}
                      InputProps={{
                        classes: {
                          root: classes.rootInput,
                          input: classes.input
                        }
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.grid}>
                    <FormControlLabel
                      control={
                        <Field
                          type="checkbox"
                          as={Checkbox}
                          id="enabled"
                          name="enabled"
                          color="primary"
                        />
                      }
                      label="Enabled"
                    />
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {submitText}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ApiKeyForm;
