import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import Link from "components/base/Link";
import React from "react";
import UsedTransactionsBar from "./UsedTransactionsBar";
import { ServiceProduct, ServiceProductWithSolution } from "./types";

interface ServiceProductItemProps {
  serviceProduct: ServiceProduct | ServiceProductWithSolution;
  onClick?: (id: string) => void;
  onDeactivate?: (id: string) => void;
  showTitle?: boolean;
}

const ServiceProductItem: React.FC<ServiceProductItemProps> = ({
  serviceProduct,
  onClick,
  onDeactivate,
  showTitle = true
}) => {
  const formattedTransactionLimit = new Intl.NumberFormat("en-US").format(
    serviceProduct.transactionLimit
  );

  return (
    <Card
      variant="outlined"
      style={{
        cursor: onClick ? "pointer" : "default",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: serviceProduct.active ? "white" : "#f5f5f5"
      }}
      onClick={onClick ? () => onClick(serviceProduct.id) : undefined}
    >
      <CardContent>
        {showTitle && (
          <Typography
            variant="subtitle1"
            component="div"
            style={{ textTransform: "uppercase" }}
            gutterBottom
          >
            {serviceProduct.name}
          </Typography>
        )}
        <Grid container spacing={1}>
          {serviceProduct.apiKey && (
            <>
              <Grid item xs={6}>
                <Typography color="textSecondary">API Key</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{serviceProduct.apiKey.name}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography color="textSecondary">Transaction Limit:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {formattedTransactionLimit}
              {!serviceProduct.active && (
                <Tooltip title="This service product is deactivated and will be ignored. Transactions associated with this service product cannot be used by the requesting API Key.">
                  <InfoIcon
                    style={{
                      marginLeft: 4,
                      verticalAlign: "middle",
                      color: "darkgray"
                    }}
                  />
                </Tooltip>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">Limit Type:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{serviceProduct.limitType}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">Expiration Date:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {new Date(serviceProduct.expirationDate).toLocaleString()}
            </Typography>
          </Grid>
          {"solutionKey" in serviceProduct && (
            <>
              <Grid item xs={6}>
                <Typography color="textSecondary">Solution</Typography>
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/solutions/${serviceProduct.solutionKey}`}
                  onClick={e => e.stopPropagation()}
                >
                  <Typography>{serviceProduct.solutionKey}</Typography>
                </Link>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography color="textSecondary">Services:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{serviceProduct.services.join(", ")}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} marginTop={2}>
          <Grid item xs={6}>
            <Typography color="textSecondary">Used Transactions</Typography>
          </Grid>
          <Grid item xs={6} spacing={0}>
            <UsedTransactionsBar serviceProduct={serviceProduct} />
          </Grid>
        </Grid>
      </CardContent>
      {onDeactivate && serviceProduct.active && (
        <CardActions>
          <Button
            size="small"
            color="secondary"
            onClick={e => {
              e.stopPropagation();
              onDeactivate(serviceProduct.id);
            }}
          >
            Deactivate
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ServiceProductItem;
