import { useApolloClient } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommandBar from "components/disw/CommandBar";
import CommandBarButton from "components/disw/CommandBarButton";
import CommandBarContainer from "components/disw/CommandBarContainer";
import DataGrid, {
  Button,
  Column,
  RequiredRule
} from "devextreme-react/data-grid";
import { EditorPreparingEvent } from "devextreme/ui/data_grid";
import notify from "devextreme/ui/notify";
import {
  PublicApiPermission,
  RestrictedPublicApiRealm
} from "features/access-management";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import "../../utils/custom-dx-theme/dx.material.custom-scheme.css";
import { createStore } from "./ApiKeyStore";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    height: "100%",
    overflow: "hidden",
    overflowY: "hidden",
    flex: 1,
    marginRight: theme.spacing(8) // leave space for the command bar
  },
  dataGrid: {
    "&.dx-widget .dx-datagrid .dx-datagrid-header-panel": {
      backgroundColor: "#F5F7F7"
    },
    "&.dx-widget .dx-datagrid .dx-toolbar": {
      backgroundColor: "#F5F7F7"
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td":
      {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        fontSize: 12,
        color: "#1E1E1E",
        padding: 0,
        paddingLeft: theme.spacing(1),
        height: 24,
        borderRight: "solid 1px #aaa",
        borderLeft: "solid 1px #aaa",
        gap: 4,
        "&:hover": { color: "red" }
      },
    "&.dx-widget .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content":
      { color: "#6e6e6e" },

    "&.dx-widget .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover:not(.dx-command-select):not(.dx-command-expand):not( .dx-editor-cell ):not(.dx-command-edit):not(.dx-datagrid-group-space)":
      {
        backgroundColor: "#E6E6E6"
      },
    "&.dx-widget .dx-datagrid .dx-row-lines > td": {
      border: "none",
      padding: "4px 8px",
      height: 40,
      color: "#464646",
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left !important",
      "&:hover": { border: "solid 2px #969696" }
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-columns-separator": {
      backgroundColor: "#464646",
      width: 1
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-columns-separator-transparent": {
      backgroundColor: "transparent"
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-row": {
      "&:hover": { backgroundColor: "#EEF6F8" }
    },
    "&.dx-widget .dx-datagrid .dx-link ": { color: "#005F87" }
  },
  addButton: {
    marginLeft: 8,
    color: "#ffffff",
    backgroundColor: "#005F87",
    border: "solid 1px #003750",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
    borderRadius: 2,
    height: 32,
    "&:hover": {
      backgroundColor: "#004D73",
      boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.3)"
    },
    "&:active": {
      backgroundColor: "#003750",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)"
    },
    "&:focus": {
      outline: "solid 2px #0F789B",
      backgroundColor: "#005F87",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
      outlineOffset: 2
    }
  },
  commandBar: {
    width: 60,
    height: "calc(100% + 48px)",
    backgroundColor: "#F0F0F0",
    marginTop: "-24px",
    marginRight: "-24px",
    borderLeft: "solid 1px rgba(0, 0, 0, 0.15)",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.3)",
    padding: "7px 7px 7px 3px"
  },
  icon: {
    height: 24,
    width: 24,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  label: { fontSize: 10, fontWeight: 600, fontFamily: "Segoe UI" },
  button: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "rgba(70, 70, 70, 1)",
    cursor: "pointer",
    marginBottom: 8,
    borderTop: "solid 2px transparent",
    "&:hover": {
      backgroundColor: "rgba(218, 236, 240, 1)",
      borderTop: "solid 2px rgba(102, 200, 222, 1)"
    },
    "&:focus": {
      outline: "solid 2px rgba(15, 120, 155, 1)",
      backgroundColor: "rgba(205, 230, 235, 1)"
    }
  }
}));
export default function ApiKeys() {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const store = useMemo(() => createStore(client), [client]);

  function onEditorPreparing(e: EditorPreparingEvent<any, any>) {
    // setReadOnlyOnEditorPreparing(e, ["Name"]);
  }

  function selectRow(e: any) {
    const selectedRows = e.component.getSelectedRowKeys();
    if (selectedRows.length) {
      e.component.collapseAll(-1);
      e.component.expandRow(selectedRows[0]);
    }
  }

  function handleCopy(e: any) {
    const { name, value } = e.row.data;
    navigator.clipboard.writeText(value).then(
      () => {
        notify(
          `API Key for '${name}' was copied to clipboard. Keep the API key confidential and give it only to the users for whom the API key was created.`,
          "success",
          8000
        );
      },
      () => {
        notify(
          "API Key was not copied. There are insufficient permissions for this action.",
          "error",
          4000
        );
      }
    );
  }

  return (
    <RestrictedPublicApiRealm
      requiredPermissions={[PublicApiPermission.ReadApiKeys]}
    >
      <RestrictedPublicApiRealm
        requiredPermissions={[PublicApiPermission.ChangeApiKeys]}
        silent={true}
      >
        {({ accessGranted: changeApiKeys }) => {
          return (
            <CommandBarContainer>
              <div className={classes.root}>
                <DataGrid
                  dataSource={store}
                  columnAutoWidth
                  onEditorPreparing={onEditorPreparing}
                  onSelectionChanged={selectRow}
                  onEditingStart={selectRow}
                  onRowInserting={selectRow}
                  onInitNewRow={e => {
                    e.data.enabled = true;
                  }}
                  showBorders={true}
                  className={classes.dataGrid}
                >
                  <Column dataField="name" caption="Name">
                    <RequiredRule />
                  </Column>
                  <Column dataField="enabled" dataType="boolean" width={70} />
                  <Column type="buttons" caption="Actions" width={80}>
                    <Button
                      hint="Copies the value of the API key to the clipboard. "
                      icon="copy"
                      onClick={handleCopy}
                    />
                    {changeApiKeys && (
                      <Button
                        icon="edit"
                        onClick={e => {
                          history.push(
                            createRoute(`/api/api-keys/${e.row?.data.id}`)
                          );
                        }}
                      />
                    )}
                  </Column>
                </DataGrid>
              </div>
              <CommandBar>
                <CommandBarButton
                  icon={<Add />}
                  label="Add"
                  onClick={() => {
                    history.push(createRoute(`/api/create-api-key`));
                  }}
                />
              </CommandBar>
            </CommandBarContainer>
          );
        }}
      </RestrictedPublicApiRealm>
    </RestrictedPublicApiRealm>
  );
}
