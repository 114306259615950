import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(_ => ({
  commandBar: {
    width: 60,
    backgroundColor: "#F0F0F0",
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    borderLeft: "solid 1px rgba(0, 0, 0, 0.15)",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.3)",
    padding: "7px 7px 7px 3px"
  }
}));

interface CommandBarProps {
  children: React.ReactNode;
}

const CommandBar: React.FC<CommandBarProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.commandBar}>{children}</div>;
};

export default CommandBar;
