import { Add } from "@mui/icons-material";
import { LinearProgress, Typography } from "@mui/material";
import CommandBar from "components/disw/CommandBar";
import CommandBarButton from "components/disw/CommandBarButton";
import CommandBarContainer from "components/disw/CommandBarContainer";
import React from "react";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import { useServiceProducts } from "../hooks/useServiceProductsHooks";
import ServiceProductList from "./ServiceProductList";

interface ServiceProductDesignerProps {
  solutionKey: string;
}

const ServiceProductDesigner: React.FC<ServiceProductDesignerProps> = ({
  solutionKey
}) => {
  const history = useHistory();
  const { loading, error, serviceProducts } = useServiceProducts(solutionKey);

  const handleAddClick = () => {
    history.push(
      createRoute(`/solutions/${solutionKey}/service-products/create`)
    );
  };

  const handleCardClick = (productId: string) => {
    history.push(
      createRoute(
        `/solutions/${solutionKey}/service-products/product/${productId}`
      )
    );
  };

  if (loading) return <LinearProgress />;
  if (error || !serviceProducts) {
    return (
      <Typography color="error">
        Error loading service products. {error?.message ?? "Unspecified error."}
      </Typography>
    );
  }

  return (
    <CommandBarContainer>
      <ServiceProductList
        serviceProducts={serviceProducts}
        onClick={handleCardClick}
      />
      <CommandBar>
        <CommandBarButton icon={<Add />} label="Add" onClick={handleAddClick} />
      </CommandBar>
    </CommandBarContainer>
  );
};

export default ServiceProductDesigner;
