import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    overflow: "hidden",
    display: "flex",
    gap: 16
  }
}));

interface CommandBarContainerProps {
  children: React.ReactNode;
}

const CommandBarContainer: React.FC<CommandBarContainerProps> = ({
  children
}) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default CommandBarContainer;
