import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { getConfigValue } from "utils/config";

type Extensions = {
  traceId?: string;
};

const cache = new InMemoryCache({
  typePolicies: {
    CloudPlatform: {
      merge: true
    },
    AccessManagement: { merge: true },
    ProjectsViewModel: { merge: true },
    Solutions: { merge: true },
    PublicApi: { merge: true }
  }
});

const errorLink = onError(({ operation, response }) => {
  const fetchResponse = operation.getContext().response;

  // Skip trace ID augmentation in case of mocked requests
  if (!fetchResponse) return;

  const headerName = "x-amzn-trace-id";
  const { headers } = fetchResponse;
  const traceId = headers.get(headerName);
  if (response && response.errors && traceId) {
    response.errors.forEach(error => {
      if (!error.extensions) (error.extensions as Extensions) = {};
      error.extensions["traceId"] = traceId;
    });
  }
});

const authLink = setContext(
  (request, res) =>
    new Promise(async (resolve, reject) => {
      const userSession: CognitoUserSession | null =
        await Auth.currentSession();
      const token = userSession?.getIdToken()?.getJwtToken();
      resolve({
        headers: {
          Authorization: token
        }
      });
    })
);
const httpLink = new HttpLink({ uri: getConfigValue("GRAPHQL_ENDPOINT") });

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  // https://www.apollographql.com/docs/react/api/apollo-client/#example-defaultoptions-object
  // The apollo docs state that the <Query/> component use watchQuery default
  // options. From testing the useQuery Hook does the same, thus watchQuery has
  // to be used in order to change default options for the useQuery Hook.
  defaultOptions: {
    mutate: {
      // https://github.com/apollographql/apollo-client/issues/4577#issuecomment-472791200
      // Apollo docs actually suggest to not set a fetch policy for mutations as
      // the only option which does not throw an exception is "no-cache"
    },
    // https://www.apollographql.com/docs/react/api/apollo-client/#apolloclient-functions
    // The docs indicate that watchQuery might receive updates on cache changes
    // therefore it might be best to keep the cache even tho the defaults are
    // set to never use but just to fill it -> use network-only instead of
    // no-cache for possible optimization without getting old cached data.
    watchQuery: {
      fetchPolicy: "network-only"
    },
    query: {
      fetchPolicy: "network-only"
    }
  }
});

function clientFactory() {
  // reuse the same client for now to ensure caching is used properly, might be clarified if this must be a singleton
  return client;
}

export default clientFactory;
