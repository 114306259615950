import { Link } from "@mui/material";

import "../authenticator-components.css";

const ImprintFooter = () => {
  return (
    <div className="footer">
      <Link
        className="imprint-link"
        href="https://ax4.com/ax4/html/201910_SIEMENS_Terms-of-Use_EN"
        target="_blanck"
      >
        By using our application, you consent to the Terms of Use as set out
        under this link.
      </Link>
      <Link
        className="imprint-link"
        href="https://siemens-digital-logistics.com/data-privacy"
        target="_blanck"
      >
        Data Privacy Notice
      </Link>
      <Link
        className="imprint-link"
        href="https://siemens-digital-logistics.com/imprint"
        target="_blanck"
      >
        Imprint
      </Link>
    </div>
  );
};

export default ImprintFooter;
