import { Alert, AlertTitle, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import notify from "devextreme/ui/notify";
import React from "react";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import ServiceProductList from "./ServiceProductList";
import { ServiceProductWithSolution } from "./types";

interface ServiceProductListForApiKeyProps {
  serviceProducts: ServiceProductWithSolution[];
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    border: "solid 1px #dcdcdc",
    width: "100%",
    marginTop: theme.spacing(2)
  },
  title: {
    color: "#1e1e1e",
    fontSize: "14.4px",
    fill: "#464646",
    font: "inherit",
    margin: 0,
    letterSpacing: 0,
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  }
}));

const ServiceProductListForApiKey: React.FC<
  ServiceProductListForApiKeyProps
> = ({ serviceProducts }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleCardClick = (solutionKey: string, serviceProductId: string) => {
    history.push(
      createRoute(
        `/solutions/${solutionKey}/service-products/product/${serviceProductId}`
      )
    );
  };

  const handleClick = (id: string) => {
    const serviceProduct = serviceProducts.find(
      serviceProduct => serviceProduct.id === id
    );
    const solutionKey = serviceProduct?.solutionKey;
    if (solutionKey) {
      handleCardClick(solutionKey, id);
    } else {
      notify("No solution found for this service product", "error", 4000);
    }
  };

  return serviceProducts.length > 0 ? (
    <div className={classes.container}>
      <Typography className={classes.title}>Active Service Products</Typography>
      <ServiceProductList
        serviceProducts={serviceProducts}
        onClick={handleClick}
      />
    </div>
  ) : (
    <div className={classes.container}>
      <Alert severity="info">
        <AlertTitle>No Service Products Assigned</AlertTitle>
        This API key currently has no service products assigned. As a result,
        the requestor using this API key will not be able to access any
        services. Please contact the solution administrators to assign the
        necessary service products and grant access to the required services.
      </Alert>
    </div>
  );
};

export default ServiceProductListForApiKey;
