import { FilterAltOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import CommandBar from "components/disw/CommandBar";
import CommandBarButton from "components/disw/CommandBarButton";
import CommandBarContainer from "components/disw/CommandBarContainer";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { useSolutionKey } from "features/solution";
import {
  QueryUseCaseList_solutions_solution_useCases_useCases,
  useQueryUseCaseList
} from "features/use-case";
import { flatMap, uniqBy } from "lodash";
import { FileExportOutline } from "mdi-material-ui";
import { useCallback, useMemo, useState } from "react";
import { writeWorkbookFile } from "utils/excel";
import { nameof, notNullOrUndefined } from "utils/typescript";
import { RoundDateRangeToFullDays } from "./helpers/roundDateRangeToFullDays";
import { createWorkbookFromTransactions } from "./helpers/transactionsExcelUtils";
import updateDateRange, { Period } from "./helpers/updateDateRange";
import { ApiKeysList_solutions_solution_useCases_apiKeys } from "./hooks/schema/ApiKeysList";
import useQueryApiKeysList from "./hooks/useApiKeysHook";
import useQueryTablesList from "./hooks/useTablesHook";
import useTransactions from "./hooks/useTransactionsHook";
import { useFetchVersions } from "./hooks/useVersionsHook";
import SelectComboBox from "./SelectComboBox";
import TimePeriodSelection from "./TimePeriodSelection";
import TransactionsGrid from "./TransactionsGrid";
import { useStyles } from "./UsageMetricsStyle";
import VersionTreeView from "./VersionTreeView";

type NameEntity = { name: string };
type NameAndIdEntity = { name: string; id: string };

export default function UsageMetrics() {
  const classNames = useStyles();
  const transactionsLimit = 100000;

  const solutionKey = useSolutionKey();
  const [openFiltersSection, setOpenFiltersSection] = useState<boolean>(true);

  const [selectedService, setSelectedService] = useState<string | undefined>(
    undefined
  );
  const [versions, setVersions] = useState<SemverVersion[] | null>([]);
  const [selectedAPIKey, setSelectedAPIKey] = useState<string | undefined>(
    undefined
  );
  const [selectedTable, setSelectedTable] = useState<string | undefined>(
    undefined
  );
  const [selectedPeriod, setSelectedPeriod] = useState<Period>("all_time");
  const [isExporting, setIsExporting] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [exportErrorMessage, setExportErrorMessage] = useState<string | null>(
    null
  );

  const {
    data: useCaseData,
    error: serviceError,
    loading: loadingServices
  } = useQueryUseCaseList(solutionKey);
  const {
    treeDataSource,
    error: versionsError,
    loading: loadingVersions
  } = useFetchVersions({
    solutionKey,
    useCaseKey: selectedService!
  });
  const {
    data: tablesData,
    loading: loadingTables,
    error: tablesError
  } = useQueryTablesList({ solutionKey, useCaseKey: selectedService! });
  const {
    data: apiKeys,
    loading: loadingApiKeys,
    error: apiKeysError
  } = useQueryApiKeysList({ solutionKey });

  const services = useMemo(
    () => useCaseData?.solutions.solution?.useCases?.useCases || [],
    [useCaseData]
  );
  const tables = useMemo(() => {
    const useCases = tablesData?.solutions?.solution?.useCases?.useCases || [];
    const allTables = flatMap(useCases, useCase => {
      return useCase.schema.input.map(input => ({ name: input.name }));
    });
    return uniqBy(allTables, "name");
  }, [tablesData]);

  const {
    loadTransactions,
    error: transactionsError,
    loading: loadingTransactions,
    transactions
  } = useTransactions();

  const onServiceChanged = useCallback((e: ValueChangedEvent) => {
    setSelectedService(e.value);
  }, []);
  const onAPIKeyChanged = useCallback((e: ValueChangedEvent) => {
    setSelectedAPIKey(e.value);
  }, []);
  const onTableChanged = useCallback((e: ValueChangedEvent) => {
    setSelectedTable(e.value);
  }, []);
  const handlePeriodChange = (e: ValueChangedEvent) => {
    setSelectedPeriod(e.value);
    updateDateRange(e.value, setStartDate, setEndDate);
  };
  const handleDateRangeChange = (e: Array<any | number | string>) => {
    const start = e[0] ?? undefined;
    const end = e[1] ?? undefined;
    setStartDate(start);
    setEndDate(end);
  };

  const applyFilter = async () => {
    setExportErrorMessage(null);
    const newFilterOptions = {
      useCaseKey: selectedService,
      useCaseVersions: versions && versions.length > 0 ? versions : null,
      tableName: selectedTable
    };

    await loadTransactions({
      solutionKey,
      useCaseKey: newFilterOptions.useCaseKey,
      useCaseVersions: newFilterOptions.useCaseVersions,
      tableName: newFilterOptions.tableName,
      apiKeyId: selectedAPIKey,
      timestampRange: RoundDateRangeToFullDays(startDate, endDate),
      limit: transactionsLimit
    });
  };

  const resetFilter = () => {
    setSelectedService(undefined);
    setVersions(null);
    setSelectedTable(undefined);
    setSelectedAPIKey(undefined);
    setSelectedPeriod("all_time");
    setStartDate(undefined);
    setEndDate(undefined);
    setExportErrorMessage(null);
  };

  const errors = [
    serviceError,
    versionsError,
    tablesError,
    apiKeysError,
    transactionsError
  ].filter(notNullOrUndefined);
  const isLimitationHintVisible = transactions.length === transactionsLimit;
  const isExportButtonDisabled = transactions.length === 0 || isExporting;

  const exportTransactions = useCallback(async () => {
    setIsExporting(true);
    setExportErrorMessage(null);
    try {
      const wb = createWorkbookFromTransactions(transactions);
      writeWorkbookFile(wb, `${solutionKey}-transactions.xlsx`);
    } catch (error) {
      if (error instanceof Error) {
        setExportErrorMessage(error.message);
      } else {
        setExportErrorMessage(
          "An unknown error occurred while exporting transactions."
        );
      }
    } finally {
      setIsExporting(false);
    }
  }, [transactions, solutionKey]);

  return (
    <CommandBarContainer>
      <div className={classNames.root}>
        {openFiltersSection && (
          <div className={classNames.flex}>
            <SelectComboBox<QueryUseCaseList_solutions_solution_useCases_useCases>
              items={services}
              displayExpr={nameof("detail.name")}
              valueExpr={nameof("detail.key")}
              value={selectedService}
              onValueChanged={onServiceChanged}
              placeholder="Select a service"
              label="Service"
              loading={loadingServices}
            />
            <VersionTreeView
              treeDataSource={treeDataSource || []}
              versions={versions}
              setVersions={setVersions}
              loadingVersions={loadingVersions}
            />
            <SelectComboBox<ApiKeysList_solutions_solution_useCases_apiKeys>
              items={apiKeys?.solutions.solution?.useCases?.apiKeys || []}
              displayExpr={nameof<NameAndIdEntity>("name")}
              valueExpr={nameof<NameAndIdEntity>("id")}
              value={selectedAPIKey}
              onValueChanged={onAPIKeyChanged}
              label="API Key/Customer"
              placeholder="Select an API Key"
              loading={loadingApiKeys}
            />
            <SelectComboBox<NameEntity>
              items={tables}
              displayExpr={nameof<NameEntity>("name")}
              valueExpr={nameof<NameEntity>("name")}
              value={selectedTable}
              onValueChanged={onTableChanged}
              label="Table"
              placeholder="Select a table"
              loading={loadingTables}
            />
            <TimePeriodSelection
              selectedPeriod={selectedPeriod}
              handlePeriodChange={handlePeriodChange}
              startDate={startDate}
              endDate={endDate}
              handleDateRangeChange={handleDateRangeChange}
            />
          </div>
        )}
        {exportErrorMessage && (
          <div className={classNames.errorMessage}>{exportErrorMessage}</div>
        )}
        <TransactionsGrid
          transactions={transactions}
          isLoading={loadingTransactions}
          errors={errors}
          withToolbar={openFiltersSection}
          toolbarItemRender={() => (
            <div className={classNames.toolbar}>
              <Button
                data-testid="reset-button"
                size="small"
                variant="outlined"
                className={classNames.resetButton}
                disableRipple
                onClick={resetFilter}
              >
                Reset
              </Button>
              <Button
                data-testid="apply-button"
                size="small"
                variant="contained"
                className={classNames.applyButton}
                disableRipple
                onClick={applyFilter}
              >
                Apply
              </Button>
            </div>
          )}
          isLimitationHintVisible={isLimitationHintVisible}
          transactionsLimit={transactionsLimit}
        />
      </div>
      <CommandBar>
        <CommandBarButton
          icon={<FilterAltOutlined />}
          label="Filters"
          onClick={() => setOpenFiltersSection(!openFiltersSection)}
          isActive={openFiltersSection}
        />
        <CommandBarButton
          icon={<FileExportOutline />}
          label={isExporting ? "Exporting..." : "Export"}
          onClick={() => {
            if (!isExportButtonDisabled) {
              exportTransactions();
            }
          }}
          disabled={isExportButtonDisabled}
        />
      </CommandBar>
    </CommandBarContainer>
  );
}
