import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import NavLink, { LinkProps } from "components/base/Link";

const useStyles = makeStyles<Theme, { enabled: boolean }>(theme =>
  createStyles({
    root: {
      width: 360,
      height: 380,
      pointerEvents: props => (props.enabled ? "auto" : "none")
    },
    content: {
      color: theme.palette.text.primary,
      height: 127,
      borderBottom: "solid 1px #e5e5e5"
    },
    name: { fontWeight: "bold" },
    description: {
      marginTop: 12
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    link: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      textDecoration: "none",
      height: "100%"
    },
    cardActions: {
      padding: theme.spacing(2)
    },
    grayscale: {
      filter: "grayscale(100%)"
    },
    graytext: {
      color: theme.palette.grey[600]
    }
  })
);

export type CardWithRolesProps = {
  name: string;
  description: string;
  targetRoles: string[];
  tooltip?: React.ReactNode;
  link: LinkProps["to"];
  testid?: string;
  image: string;
  enabled?: boolean;
};

export default function CardWithRoles({
  name,
  description,
  targetRoles = [],
  link,
  testid,
  image,
  enabled = true
}: CardWithRolesProps): JSX.Element {
  const classes = useStyles({ enabled: enabled! });

  return (
    <Card className={classes.root}>
      <NavLink to={link} className={classes.link}>
        <CardMedia
          className={clsx(classes.media, {
            [classes.grayscale as string]: !enabled
          })}
          image={image}
        />
        <CardContent
          className={clsx(classes.content, {
            [classes.graytext as string]: !enabled
          })}
          data-testid={enabled ? testid : null}
        >
          <Typography className={classes.name}>{name}</Typography>
          <Typography
            className={classes.description}
            variant="body2"
            component="p"
          >
            {description}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          {targetRoles.map(role => (
            <Chip label={role} key={role} variant="outlined" size="small" />
          ))}
        </CardActions>
      </NavLink>
    </Card>
  );
}
