import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
  button: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "rgba(70, 70, 70, 1)",
    cursor: "pointer",
    marginBottom: 8,
    borderTop: "solid 2px transparent",
    "&:hover": {
      backgroundColor: "rgba(218, 236, 240, 1)",
      borderTop: "solid 2px rgba(102, 200, 222, 1)"
    },
    "&:focus": {
      outline: "solid 2px rgba(15, 120, 155, 1)",
      backgroundColor: "rgba(205, 230, 235, 1)"
    }
  },
  selectedButton: {
    backgroundColor: "rgba(218, 236, 240, 1)",
    borderTop: "solid 2px rgba(102, 200, 222, 1)"
  },
  disabledButton: {
    cursor: "default",
    "&:hover": {
      backgroundColor: "transparent",
      borderTop: "solid 2px transparent"
    }
  },
  icon: {
    height: 24,
    width: 24,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  label: {
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Segoe UI"
  }
}));

interface CommandBarButtonProps {
  icon: React.ReactElement;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  isActive?: boolean;
}

const CommandBarButton: React.FC<CommandBarButtonProps> = ({
  icon,
  label,
  onClick,
  disabled = false,
  isActive = false
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.button, {
        [classes.selectedButton]: isActive,
        [classes.disabledButton]: disabled
      })}
      onClick={!disabled ? onClick : undefined}
      aria-disabled={disabled}
    >
      <IconButton className={classes.icon} disabled={disabled}>
        {icon}
      </IconButton>
      <Typography className={classes.label}>{label}</Typography>
    </div>
  );
};

export default CommandBarButton;
