import { LimitType } from "model/schema/globalTypes";
import { ApiKeyWithServiceProducts_publicApi_apiKeys_apiKey_attachedServiceProducts } from "../../public-api/schema/ApiKeyWithServiceProducts";

export interface ServiceProductCreatable {
  apiKeyId: string;
  name: string;
  limitType: LimitType;
  transactionLimit: number;
  expirationDate: Date;
  services: Key[];
}

export interface ServiceProduct extends ServiceProductCreatable {
  readonly id: string;
  readonly apiKey?: ApiKey;
  readonly active: boolean;
  readonly usedTransactions: number;
}

export interface ServiceProductWithSolution extends ServiceProductCreatable {
  readonly id: string;
  readonly apiKey?: ApiKey;
  readonly solutionKey: string;
  readonly active: boolean;
  readonly usedTransactions: number;
}

export interface ApiKey {
  readonly name: string;
  readonly id: string;
}

export function mapAttachedServiceProductsToServiceProductsWithSolution(
  attachedServiceProducts: ApiKeyWithServiceProducts_publicApi_apiKeys_apiKey_attachedServiceProducts[],
  apiKeyId: string
): ServiceProductWithSolution[] {
  return attachedServiceProducts.map(({ solutionKey, serviceProduct }) => ({
    id: serviceProduct.id,
    name: serviceProduct.name,
    transactionLimit: serviceProduct.transactionLimit,
    usedTransactions: serviceProduct.usedTransactions,
    limitType: serviceProduct.limitType,
    expirationDate: new Date(serviceProduct.expirationDate),
    services: serviceProduct.services,
    active: serviceProduct.active,
    apiKeyId,
    solutionKey
  }));
}
