import { useMutation } from "@apollo/client";
import notify from "devextreme/ui/notify";
import { TransactionPackage } from "model/schema/globalTypes";
import React from "react";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import ApiKeyForm from "./ApiKeyForm";
import { mutationAddApiKey } from "./ApiKeyStore";
import { AddApiKey, AddApiKeyVariables } from "./schema/AddApiKey";

const ApiKeyCreate: React.FC = () => {
  const history = useHistory();
  const [createApiKey, { error: createApiKeyError }] = useMutation<
    AddApiKey,
    AddApiKeyVariables
  >(mutationAddApiKey);

  return (
    <ApiKeyForm
      title="API Key"
      submitText="Create API Key"
      initialState={{
        name: "",
        transactionPackage: TransactionPackage.Large,
        enabled: true
      }}
      onSubmit={async apiKeyDefinition => {
        await createApiKey({
          variables: {
            apiKeyDefinition
          }
        });
        if (!createApiKeyError) {
          history.push(createRoute(`/api/api-keys`));
        } else {
          notify(
            "Unable to create API Key. " + createApiKeyError.message,
            "error",
            4000
          );
        }
      }}
    />
  );
};

export default ApiKeyCreate;
