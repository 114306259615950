import { useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography
} from "@mui/material";
import notify from "devextreme/ui/notify";
import {
  PublicApiPermission,
  RestrictedPublicApiRealm
} from "features/access-management";
import {
  mapAttachedServiceProductsToServiceProductsWithSolution,
  ServiceProductListForApiKey
} from "features/billing-and-metering";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import ApiKeyForm from "./ApiKeyForm";
import { mutationRemoveApiKey, mutationUpdateApiKey } from "./ApiKeyStore";
import { RemoveApiKey, RemoveApiKeyVariables } from "./schema/RemoveApiKey";
import { UpdateApiKey, UpdateApiKeyVariables } from "./schema/UpdateApiKey";
import { useApiKey } from "./useApiKey";

interface ApiKeyEditProps {
  apiKeyId: string;
}

const ApiKeyEdit: React.FC<ApiKeyEditProps> = ({ apiKeyId }) => {
  const history = useHistory();
  const apiKeyRoute = createRoute(`/api/api-keys`);
  const { apiKey, loading, error } = useApiKey(apiKeyId);
  const [open, setOpen] = useState(false);

  const [updateApiKey, { error: updateApiKeyError }] = useMutation<
    UpdateApiKey,
    UpdateApiKeyVariables
  >(mutationUpdateApiKey);
  const [removeApiKey, { error: removeApiKeyError }] = useMutation<
    RemoveApiKey,
    RemoveApiKeyVariables
  >(mutationRemoveApiKey);

  const handleDeleteClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    await removeApiKey({ variables: { id: apiKeyId } });
    if (!removeApiKeyError) {
      history.push(apiKeyRoute);
    } else {
      notify(
        `Unable to delete API key. ${removeApiKeyError.message}`,
        "error",
        4000
      );
    }
  };

  if (loading) <LinearProgress />;

  if (error) {
    return (
      <Grid container justifyContent="center">
        <Typography color="error">
          Error loading API key data. {error?.message}
        </Typography>
      </Grid>
    );
  }

  if (!apiKey) {
    return (
      <Grid container justifyContent="center">
        <Typography color="error">API Key not found</Typography>
      </Grid>
    );
  }

  return (
    <div>
      <ApiKeyForm
        title="General API Key Settings"
        submitText="Update API Key"
        initialState={apiKey}
        onSubmit={async ({ name, transactionPackage, enabled }) => {
          await updateApiKey({
            variables: {
              apiKeyId,
              apiKeyDefinition: {
                name,
                transactionPackage,
                enabled
              }
            }
          });
          if (!updateApiKeyError) {
            history.push(apiKeyRoute);
          } else {
            notify(
              `Unable to save API key. ${updateApiKeyError.message}`,
              "error",
              4000
            );
          }
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px"
        }}
      >
        <Button
          startIcon={<DeleteIcon />}
          onClick={handleDeleteClick}
          color="secondary"
        >
          Delete API Key
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm API Key Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to delete this API key. This action is irreversible
            and the API key will be permanently removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <RestrictedPublicApiRealm
        requiredPermissions={[PublicApiPermission.ReadApiKeyAccess]}
      >
        <ServiceProductListForApiKey
          serviceProducts={mapAttachedServiceProductsToServiceProductsWithSolution(
            apiKey.attachedServiceProducts,
            apiKey.id
          )}
        />
      </RestrictedPublicApiRealm>
    </div>
  );
};

export default ApiKeyEdit;
