import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  Transactions,
  Transactions_solutions_solution_useCases_transactions,
  TransactionsVariables
} from "./schema/Transactions";

const transcationsList = gql`
  query Transactions(
    $solutionKey: Key!
    $useCaseKey: Key
    $useCaseVersions: [SemverVersion!]
    $tableName: String
    $apiKeyId: String
    $timestampRange: DateTimeRangeFilterInput
    $limit: Int
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          transactions(
            useCaseKey: $useCaseKey
            useCaseVersions: $useCaseVersions
            tableName: $tableName
            apiKeyId: $apiKeyId
            timestampRange: $timestampRange
            limit: $limit
          ) {
            requestId
            tableName
            timestamp
            transactionCount
            useCaseKey
            useCaseVersion
            calculationStatus
            apiKey {
              name
              id
            }
            chargedServiceProduct {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export type UseCaseTransactions =
  Transactions_solutions_solution_useCases_transactions;

export default function useTransactions() {
  const client = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError>();
  const [transactions, setTransactions] = useState<
    Transactions_solutions_solution_useCases_transactions[]
  >([]);

  const loadTransactions = useCallback(
    async (variables: TransactionsVariables) => {
      setLoading(true);
      setError(undefined);

      try {
        const { data, errors } = await client.query<
          Transactions,
          TransactionsVariables
        >({
          query: transcationsList,
          variables,
          errorPolicy: "all"
        });

        if (errors) {
          const error = new ApolloError({ graphQLErrors: errors });
          setError(error);
        }

        const transactions =
          data?.solutions.solution?.useCases?.transactions || [];
        setTransactions(transactions);
      } catch (err) {
        setError(err as ApolloError);
      } finally {
        setLoading(false);
      }
    },
    [client]
  );

  return { loadTransactions, error, loading, transactions };
}
