import { Grid, Theme, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ServiceProduct } from "./types";

interface StyleProps {
  active: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  barContainer: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    width: "100%",
    height: "18px",
    borderRadius: "0px",
    overflow: "hidden",
    backgroundColor: "#e0e0e0"
  },
  used: props => ({
    backgroundColor: props.active ? theme.palette.primary.main : "#9e9e9e" // Current color for used transactions or dark gray when inactive
  }),
  remaining: props => ({
    backgroundColor: props.active ? "#e0e0e0" : "#bdbdbd"
  }),
  exceeded: props => ({
    backgroundColor: props.active ? theme.palette.secondary.main : "#616161" // Current color for exceeded transactions or darker gray when inactive
  }),
  tooltipTable: {
    minWidth: "150px",
    "& td": {
      padding: "4px 8px"
    }
  },
  percentageText: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "0.8rem"
  },
  usedText: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "0.8rem"
  },
  usedTextSecondary: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5)
  }
}));

interface TransactionQuotaBarProps {
  serviceProduct: ServiceProduct;
}

const UsedTransactionsBar: React.FC<TransactionQuotaBarProps> = ({
  serviceProduct
}) => {
  const classes = useStyles({ active: serviceProduct.active });

  const usedTransactions = serviceProduct.usedTransactions;
  const transactionLimit = serviceProduct.transactionLimit;
  const remainingTransactions = Math.max(
    transactionLimit - usedTransactions,
    0
  );
  const exceededTransactions = Math.max(usedTransactions - transactionLimit, 0);

  const usedPercentage = Math.min(1, usedTransactions / transactionLimit) * 100;
  const remainingPercentage = (remainingTransactions / transactionLimit) * 100;
  const exceededPercentage = (exceededTransactions / usedTransactions) * 100;
  const percentage = Math.round((usedTransactions / transactionLimit) * 100);

  const tooltipContent = (
    <table className={classes.tooltipTable}>
      <tbody>
        {usedTransactions > 0 && (
          <tr>
            <td>Used:</td>
            <td>{usedTransactions}</td>
          </tr>
        )}
        {remainingTransactions > 0 && (
          <tr>
            <td>Remaining:</td>
            <td>{remainingTransactions}</td>
          </tr>
        )}
        {exceededTransactions > 0 && (
          <tr>
            <td>Exceeded:</td>
            <td>{exceededTransactions}</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.container}>
        <Tooltip title={tooltipContent} arrow>
          <div className={classes.barContainer}>
            <div
              className={classes.used}
              style={{ width: `${usedPercentage}%` }}
            />
            <div
              className={classes.remaining}
              style={{ width: `${remainingPercentage}%` }}
            />
            <div
              className={classes.exceeded}
              style={{ width: `${exceededPercentage}%` }}
            />
          </div>
        </Tooltip>
        <Typography className={classes.percentageText}>
          {percentage}%
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.usedText}>
          {usedTransactions <= transactionLimit ? (
            <>
              {usedTransactions}/{transactionLimit}
              <span className={classes.usedTextSecondary}>Used</span>
            </>
          ) : (
            <>
              {exceededTransactions}
              <span className={classes.usedTextSecondary}>Exceeded</span>
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UsedTransactionsBar;
