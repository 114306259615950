import { LinearProgress } from "@mui/material";
import { useSolutionKey } from "features/solution";
import { NoUseCaseFound } from "features/use-case";
import React from "react";
import PublishFormWrapper from "./PublishFormWrapper";
import useQueryUseCaseByKey from "./hooks/useQueryUseCaseByKey";
export { default as CreateUseCase } from "./PublishFormWrapper";

type UseCasePublisherProps = {
  useCaseKey: string;
};

export const EditUseCase: React.FC<UseCasePublisherProps> = ({
  useCaseKey
}) => {
  const solutionKey = useSolutionKey();
  const { data: useCaseByKey, loading: isLoadingUseCase } =
    useQueryUseCaseByKey({
      solutionKey,
      useCaseKey
    });

  const useCaseDetail =
    useCaseByKey?.solutions.solution?.useCases?.useCase?.detail;
  const isNotTechnical = (tag: string) => !tag.includes(":=");
  const tags = useCaseDetail?.tags?.filter(isNotTechnical) || [];
  const description = useCaseDetail?.description || "";

  if (isLoadingUseCase) return <LinearProgress />;
  if (useCaseDetail)
    return (
      <PublishFormWrapper
        initialState={{
          calculationRuleAddress: "",
          ...useCaseDetail,
          tags,
          description,
          autoGeneratedKey: false
        }}
        updateUseCase={true}
      />
    );
  return <NoUseCaseFound />;
};
