import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import {
  useDeactivateServiceProduct,
  useServiceProduct
} from "../hooks/useServiceProductsHooks";
import ServiceProductItem from "./ServiceProductItem";

interface ServiceProductDetailedProps {
  solutionKey: string;
  serviceProductId: string;
}

const ServiceProductDetailed: React.FC<ServiceProductDetailedProps> = ({
  solutionKey,
  serviceProductId
}) => {
  const history = useHistory();
  const { serviceProduct, loading, error } = useServiceProduct(
    solutionKey,
    serviceProductId
  );
  const [open, setOpen] = useState(false);
  const [
    deactivateServiceProduct,
    { loading: deactivating, error: deactivateError }
  ] = useDeactivateServiceProduct();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDeactivate = async () => {
    try {
      await deactivateServiceProduct({
        variables: { solutionKey, serviceProductId }
      });
      setOpen(false);
      history.push(createRoute(`/solutions/${solutionKey}/service-products`));
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Typography color="error">
        Error loading service product: {error.message}
      </Typography>
    );

  return (
    <div>
      {serviceProduct ? (
        <div>
          <ServiceProductItem
            serviceProduct={serviceProduct}
            onDeactivate={() => setOpen(true)}
            showTitle={false}
          />
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm Service Product Deactivation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are about to deactivate this service product. This action is
                irreversible and will permanently deactivate the service
                product. Deactivating a service product is generally not
                recommended after it has been sold.
              </DialogContentText>
              {deactivating && <LinearProgress />}
              {deactivateError && (
                <Typography color="error">
                  Error deactivating service product: {deactivateError.message}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleConfirmDeactivate}
                color="secondary"
                disabled={deactivating}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <Typography>No service product found</Typography>
      )}
    </div>
  );
};

export default ServiceProductDetailed;
