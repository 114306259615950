import { DeepOmit } from "utils/typescript";
import * as XLSX from "xlsx";
import { Transactions_solutions_solution_useCases_transactions } from "../hooks/schema/Transactions";

export type TransactionItem = DeepOmit<
  Transactions_solutions_solution_useCases_transactions,
  "__typename"
>;

/**
 * Create a workbook from transactions with specified column order and custom headers.
 * @param {Array} transactions - The transactions data.
 * @returns {Object} - The workbook object.
 */
export function createWorkbookFromTransactions(
  transactions: TransactionItem[]
) {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Transactions Export",
    Author: "Transactions Generator",
    Subject: "",
    CreatedDate: new Date()
  };

  // Format transactions according to the specified column order and custom headers
  const formattedTransactions = transactions.map(transaction => {
    const formattedTransaction = {
      Service: transaction.useCaseKey,
      Version: transaction.useCaseVersion,
      "Customer (API Key)": transaction.apiKey?.name,
      Table: transaction.tableName,
      Transactions: transaction.transactionCount,
      "Request ID": transaction.requestId,
      Timestamp: new Date(transaction.timestamp),
      "Calculation Status": transaction.calculationStatus,
      "Charged Service Product": transaction.chargedServiceProduct?.name
    };
    return formattedTransaction;
  });

  // Create worksheet from formatted transactions
  const worksheet = XLSX.utils.json_to_sheet(formattedTransactions, {
    dateNF: "d/m/yy h:mm:ss" // Format is localized by Excel see https://docs.sheetjs.com/docs/csf/features/nf#miscellany
  });
  XLSX.utils.book_append_sheet(wb, worksheet, "Transactions");

  return wb;
}
