import makeStyles from "@mui/styles/makeStyles";

//@ts-ignore
export const useStyles = makeStyles(theme => ({
  detail: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(4),
    overflow: "hidden",
    position: "relative",
    borderBottom: "solid 1px rgb(0,0,0,0.08)"
  },
  detailInner: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%"
  },
  imgOverlay: {
    height: "100%",
    width: "100%",
    background:
      "linear-gradient(to right,rgba(80,190,190,0.7) 0,rgba(0,153,153,0.7) 50%,rgba(0,153,176,0.7) 83%,rgba(0,153,203,0.7) 100%)"
  },
  imgInner: {
    backgroundColor: "rgb(0,0,0,0.08)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  defaultIcon: { height: 100, width: 100 },
  image: { maxHeight: "100%", maxWidth: 100, marginRight: 8 },
  anotherDetails: {
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    textAlign: "left",
    flex: 1,
    marginLeft: theme.spacing(1)
  },
  defaultText: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "initial"
    }
  },
  name: {
    color: theme.palette.primary.main,
    fontSize: 14,
    letterSpacing: 2,
    textTransform: "uppercase"
  },
  description: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    color: "#B1B1B1",
    width: "100%",
    whiteSpace: "pre-wrap",
    maxHeight: 300,
    overflow: "auto",
    wordBreak: "break-word",
    overflowX: "hidden"
  },
  transactionTable: {
    width: "100%",
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontWeight: "bold"
  },
  transactionTableName: {
    color: theme.palette.primary.dark,
    marginLeft: theme.spacing(1)
  },
  version: { fontWeight: "bold" },
  apiButton: {
    color: "#ffffff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8
    }
  }
}));
