import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  Theme
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    }
  })
);

type SelectProps<TValue> = {
  options: TValue[];
  label: string;
  inputValue?: string;
  name?: string;
  value: TValue | null;
  error?: boolean;
  helperText?: React.ReactNode;
  loading?: boolean;
  disabled?: AutocompleteProps<TValue, false, false, false>["disabled"];
  onBlur?: React.FocusEventHandler;
  onChange: (event: ChangeEvent<{}>, value: TValue | null) => void;
  onInputChange?: (event: ChangeEvent<{}>, inputValue: string) => void;
  getOptionLabel?: (option: TValue) => string;
  renderOption?: (option: TValue) => React.ReactNode;
  id: string;
};

export default function Select<TValue>(props: SelectProps<TValue>) {
  const jss = useStyles();
  return (
    <Autocomplete
      loading={props.loading}
      className={jss.root}
      getOptionLabel={props.getOptionLabel}
      renderOption={
        props.renderOption !== undefined
          ? (p, value) => <li {...p}>{props.renderOption!(value)}</li>
          : undefined
      }
      options={props.options}
      disabled={props.disabled}
      renderInput={params => (
        <TextField
          {...params}
          name={props.name}
          helperText={props.helperText}
          error={props.error}
          label={props.label}
          variant="outlined"
          fullWidth
        />
      )}
      onBlur={props.onBlur}
      onChange={props.onChange}
      value={props.value}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
      id={props.id}
    />
  );
}
