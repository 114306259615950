import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { queryApiKeyWithServiceProducts } from "./ApiKeyStore";
import {
  ApiKeyWithServiceProducts,
  ApiKeyWithServiceProducts_publicApi_apiKeys_apiKey,
  ApiKeyWithServiceProductsVariables
} from "./schema/ApiKeyWithServiceProducts";

export const useApiKey = (apiKeyId: string) => {
  const { data, loading, error } = useQuery<
    ApiKeyWithServiceProducts,
    ApiKeyWithServiceProductsVariables
  >(queryApiKeyWithServiceProducts, {
    variables: { id: apiKeyId }
  });

  const [apiKey, setApiKey] =
    useState<ApiKeyWithServiceProducts_publicApi_apiKeys_apiKey | null>(null);

  useEffect(() => {
    if (data && data.publicApi.apiKeys.apiKey) {
      setApiKey(data.publicApi.apiKeys.apiKey);
    }
  }, [data]);

  return { apiKey, loading, error };
};
