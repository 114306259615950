import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { ServiceProduct } from "../service-products/types";
import {
  CreateServiceProduct,
  CreateServiceProductVariables
} from "./schema/CreateServiceProduct";
import {
  DeactivateSolutionServiceProduct,
  DeactivateSolutionServiceProductVariables
} from "./schema/DeactivateSolutionServiceProduct";
import {
  SolutionServiceProduct,
  SolutionServiceProduct_solutions_solution_useCases_serviceProducts_serviceProduct,
  SolutionServiceProductVariables
} from "./schema/SolutionServiceProduct";
import {
  SolutionServiceProducts,
  SolutionServiceProductsVariables
} from "./schema/SolutionServiceProducts";

const GET_SERVICE_PRODUCT = gql`
  query SolutionServiceProduct($solutionKey: Key!, $serviceProductId: ID!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          serviceProducts {
            serviceProduct(serviceProductId: $serviceProductId) {
              id
              name
              transactionLimit
              usedTransactions
              limitType
              expirationDate
              services
              apiKeyId
              apiKey {
                name
                id
              }
              active
            }
          }
        }
      }
    }
  }
`;

const GET_SERVICE_PRODUCTS = gql`
  query SolutionServiceProducts($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          serviceProducts {
            serviceProducts {
              id
              name
              transactionLimit
              usedTransactions
              limitType
              expirationDate
              services
              apiKeyId
              apiKey {
                name
                id
              }
              active
            }
          }
        }
      }
    }
  }
`;

const CREATE_SERVICE_PRODUCT = gql`
  mutation CreateServiceProduct(
    $solutionKey: Key!
    $apiKeyId: String!
    $expirationDate: DateTime!
    $limitType: LimitType!
    $name: String!
    $transactionLimit: Int!
    $services: [Key!]!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          serviceProducts {
            create(
              apiKeyId: $apiKeyId
              expirationDate: $expirationDate
              limitType: $limitType
              name: $name
              transactionLimit: $transactionLimit
              services: $services
            ) {
              id
              name
              transactionLimit
              limitType
              expirationDate
              services
              apiKeyId
              apiKey {
                name
                id
              }
              active
            }
          }
        }
      }
    }
  }
`;

const DEACTIVATE_SERVICE_PRODUCT = gql`
  mutation DeactivateSolutionServiceProduct(
    $solutionKey: Key!
    $serviceProductId: ID!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          serviceProducts {
            deactivate(serviceProductId: $serviceProductId) {
              active
              id
            }
          }
        }
      }
    }
  }
`;

export const useServiceProduct = (
  solutionKey: string,
  serviceProductId: string
) => {
  const [serviceProduct, setServiceProduct] = useState<ServiceProduct | null>(
    null
  );
  const { loading, error, data } = useQuery<
    SolutionServiceProduct,
    SolutionServiceProductVariables
  >(GET_SERVICE_PRODUCT, {
    variables: { solutionKey, serviceProductId }
  });

  useEffect(() => {
    if (data) {
      const product =
        data.solutions.solution?.useCases?.serviceProducts?.serviceProduct;
      setServiceProduct(product ? mapServiceProduct(product) : null);
    }
  }, [data]);

  return { serviceProduct, loading, error };
};

export const useServiceProducts = (solutionKey: string) => {
  const { loading, error, data } = useQuery<
    SolutionServiceProducts,
    SolutionServiceProductsVariables
  >(GET_SERVICE_PRODUCTS, {
    variables: { solutionKey }
  });

  const serviceProducts: ServiceProduct[] =
    data?.solutions.solution?.useCases?.serviceProducts?.serviceProducts.map(
      mapServiceProduct
    ) || [];

  return { serviceProducts, loading, error };
};

export const useCreateServiceProduct = () => {
  return useMutation<CreateServiceProduct, CreateServiceProductVariables>(
    CREATE_SERVICE_PRODUCT
  );
};

export const useDeactivateServiceProduct = () => {
  return useMutation<
    DeactivateSolutionServiceProduct,
    DeactivateSolutionServiceProductVariables
  >(DEACTIVATE_SERVICE_PRODUCT);
};

function mapServiceProduct({
  id,
  name,
  transactionLimit,
  usedTransactions,
  limitType,
  expirationDate,
  services,
  apiKeyId,
  apiKey,
  active
}: SolutionServiceProduct_solutions_solution_useCases_serviceProducts_serviceProduct): ServiceProduct {
  return {
    id,
    name,
    transactionLimit,
    usedTransactions,
    limitType,
    expirationDate: new Date(expirationDate),
    services,
    apiKeyId,
    apiKey,
    active
  };
}
