import { gql, useQuery } from "@apollo/client";
import { LinearProgress, Typography } from "@mui/material";
import { useQueryUseCaseList } from "features/use-case";
import React from "react";
import { useHistory } from "react-router-dom";
import { useCreateServiceProduct } from "../hooks/useServiceProductsHooks";
import { GetApiKeys } from "./schema/GetApiKeys";
import ServiceProductForm from "./ServiceProductForm";
import { ServiceProductCreatable } from "./types";

const QUERY_API_KEYS = gql`
  query GetApiKeys {
    publicApi {
      apiKeys {
        apiKeys {
          id
          name
        }
      }
    }
  }
`;

interface ServiceProductFormConnectedProps {
  solutionKey: string;
}

const ServiceProductFormConnected: React.FC<
  ServiceProductFormConnectedProps
> = ({ solutionKey }) => {
  const history = useHistory();
  const [createServiceProduct, { loading: submitting, error: submitError }] =
    useCreateServiceProduct();

  const { data, loading, error } = useQuery<GetApiKeys>(QUERY_API_KEYS);
  const apiKeys =
    data?.publicApi.apiKeys.apiKeys || ([] as { id: string; name: string }[]);

  const {
    data: servicesData,
    loading: loadingServices,
    error: loadingServicesError
  } = useQueryUseCaseList(solutionKey);

  const services =
    servicesData?.solutions.solution?.useCases?.useCases?.map(
      service => service.detail.key
    ) || [];

  const handleSubmit = async (serviceProduct: ServiceProductCreatable) => {
    const expirationDate = new Date(serviceProduct.expirationDate);
    const { data } = await createServiceProduct({
      variables: {
        solutionKey,
        apiKeyId: serviceProduct.apiKeyId,
        expirationDate: expirationDate.toISOString(),
        limitType: serviceProduct.limitType,
        name: serviceProduct.name,
        transactionLimit: serviceProduct.transactionLimit,
        services: serviceProduct.services
      }
    });

    if (data) {
      history.push(`/solutions/${solutionKey}/service-products`);
    }
  };

  if (loading || loadingServices || submitting) return <LinearProgress />;
  if (error || loadingServicesError || submitError) {
    const errorMessage =
      error?.message || loadingServicesError?.message || submitError?.message;
    return (
      <Typography color="error">Error loading data. {errorMessage}</Typography>
    );
  }

  return (
    <ServiceProductForm
      solutionKey={solutionKey}
      onSubmit={handleSubmit}
      apiKeys={apiKeys}
      avilableProductServices={services}
    />
  );
};

export default ServiceProductFormConnected;
